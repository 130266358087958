<template>
  <div>
    <nav-bar />
    <div class="outside">
      <div class="container content">
        <el-container style="height: 700px; border: 1px solid #eee">
          <el-aside width="230px" style="background-color: rgb(238, 241, 246)">
            <el-menu class="el-menu-vertical-demo" :default-active="activePath" @open="handleOpen" @close="handleClose" :unique-opened="true">
              <el-submenu :index="key.toString()" v-for="(item, key) in notices" :key="item.id">
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item :index="subKey.toString()" v-for="(subItem, subKey) in item.children" :key="subItem.id" @click="saveNavState('/' + subItem.id, subItem.id), handelClick(subItem.id)">
                  <template slot="title">
                    <span>{{ subItem.title }}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-aside>
          <el-main style="width: 100%">
            <div class="item">
              <div class="title">
                <img class="gonggao" src="~assets/icons/gonggao.png" style="vertical-align:middle;" />
                系统公告<span id="">{{ navigator }}</span>
              </div>

		<!-- 公告列表 开始  -->
              <div class="list">
                <ul>
                  <li v-for="(nItem, nKey) in noticelist">
                    <a :href="url + '/notice?ids=' + nItem.id">{{ nItem.title }}</a><span>{{ timeConverDate(nItem.createtime) }}</span>
                  </li>
                </ul>
              </div>
		<!-- 公告列表 结束  -->

		<!-- 分页 开始  -->
	      <div class="pagination" style="text-align:right;padding:5px 30px;">
		<el-pagination
		  background
		  @current-change="currentChange"
		  :page-size="this.pageinfo.per_page"
		  layout="prev, pager, next"
		  :current-page="this.pageinfo.current_page"
		  :total="this.pageinfo.total">
		</el-pagination>
	      </div>
		<!-- 分页 结束  -->

            </div>
          </el-main>
        </el-container>
      </div>
    <bottom-footer/>
    </div>
  </div>
</template>

<script>
import { timeConverDate } from 'utils/utils'
import NavBar from 'components/navbar/NavBar'
import BottomFooter from 'components/bottomfooter/BottomFooter.vue'
// import NoticeListItem from './childComps/NoticeListItem'
import { getNoticeAll, getNoticeSelect, getNoticeList } from 'network/api'

export default {
  components: {
    NavBar,
    BottomFooter
    // NoticeListItem
  },
  data () {
    return {
      notices: [],
      noticelist: [],
      content: [],
      pageinfo: [],
      activePath: '0', // 被激活的菜单
      typedata: 0,
      currentPage: 0,
      url: '',
      navigator: ''
    }
  },
  created () {
    this.typedata = this.getQueryString('typedata') ?? 0
    this.getNoticeAll()
    this.getList()
  },
  computed: {
    // 使用时间戳转化函数
    timeConverDate() {
      return timeConverDate
    }
  },
  methods: {
    currentChange (val) {
      this.currentPage = val
      this.getList()
    },
    // 获取公告列表
    getList(){
        getNoticeList({
	    typedata: this.typedata,
            page: this.currentPage
        }).then((res) => {
            this.noticelist = res.data.data
	    this.pageinfo.total = res.data.total
	    this.pageinfo.per_page = res.data.per_page
	    this.pageinfo.current_page = res.data.current_page
	    this.pageinfo.last_page = res.data.last_page

	    // 系统公告：系统升级、仓库快递、内测邀请、常见问题、头部公告[1 2 3 4 5]
	    var typename = ''
	    switch (this.typedata) {
		case '1':	typename = '系统升级'
			break
		case '2':	typename = '仓库快递'
			break
		case '3':	typename = '内测邀请'
			break
		case '4':	typename = '常见问题'
			break
		case '5':	typename = '头部公告'
			break
	    }
	    if(typename != '')	this.navigator = ' -> '+typename
        })
    },
    // 保存激活的菜单状态
    saveNavState (path, id) {
      this.activePath = path
      this.getNoticeAll(id)
    },
    handleOpen (key, keyPath) {
      // console.log(key)
      // console.log(keyPath)
      // this.activePath = ''
      // this.notices.forEach(function (item, k) {
      //   // console.log(typeof (key))
      //   // console.log(typeof (k))
      //   if (key.toString() === k.toString()) {
      //     item.children.forEach(function (subItem, subKey) {
      //       console.log(subKey)
      //       if (subKey === 0) {
      //         console.log(subKey, '-----81----')
      //         const id = subKey
      //         console.log(typeof (id))
      //         this.getNoticeAll() // 从这里重新取下id
      //         this.handelClick()
      //       }
      //     })
      //   }
      // })
    },
    handelClick (subItemId) {
      // console.log(subItemId)
      const id = subItemId
      this.getNoticeAll(id)
    },
    handleClose (key, keyPath) {
      // this.activePath = key
      // console.log(key, keyPath)
    },
    // 获取全部公告
    getNoticeAll (params) {
      getNoticeAll(params).then((res) => {
        if (res.code !== 1) { return this.$message({ message: res.msg, type: 'warning' }) }
        if (params) {
          this.content = res.data
        } else {
          this.notices = res.data.list
          // list = JSON.parse(`[${res.value}]`)
          this.content = res.data.content
        }
      })
    },
    // 获取html页面网址传递参数
    getQueryString(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }
  }
}
</script>

<style scoped src="styles/views/notice/notice.css">
</style>

<style>
.el-submenu /deep/.el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 220px;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-menu-item:focus /deep/ .el-menu-item:hover {
    outline: 0;
    background-color: #e2e6fd;
    color: #5555f7;
}
.el-tabs__content {
  margin-top: 100px;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.45);
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #409eff;
  border: 1px solid #409eff;
  background-color: #ffffff;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.list {
  padding:5px;
}
.list ul{
  margin:15px 30px 5px 20px;
}
.list li{
    height:35px;
    line-height:35px;
    font-size:16px;
    border-bottom:1px dotted #EEE;
    margin-bottom:5px;
    padding:0 10px 0 5px;
}
.list li span{
    float:right;
    color:#999;
}
.gonggao{
  margin-right:5px;vertical-align:middle;
}
</style>
